<template>
  <DynamicLink
    :to="to"
    :class="
      cn(
        'inline-flex cursor-pointer items-center gap-1 text-inherit underline hover:text-blue-600 [&[inert]]:opacity-30',
        $attrs.class as string,
      )
    "
  >
    <slot />
  </DynamicLink>
</template>

<script lang="tsx">
import type { FunctionalComponent } from "vue";
import { type RouteLocationRaw, RouterLink } from "vue-router";
import { cn } from "$app/utils/styles";
export const DynamicLink: FunctionalComponent<{
    to: string | RouteLocationRaw | undefined;
}> = ({ to }, { slots, attrs }) => to && (typeof to === "object" || (!to.includes(":") && attrs.download == null)) ? (<RouterLink to={to}>{slots.default?.()}</RouterLink>) : (<a href={to}>{slots.default?.()}</a>);
DynamicLink.props = ["to"];
//__BLOCK END__
//</script>

<script setup lang="tsx">
defineProps<{
    to?: string | RouteLocationRaw;
}>();
</script>
