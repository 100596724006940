<template>
  <main class="flex h-full flex-col items-center justify-center gap-4 bg-black text-center text-white">
    <img :src="logo" class="size-40 invert" alt="" />
    <div class="text-3xl font-bold">{{ heading }}</div>
    <div>{{ text }}</div>
    <Link :to="{ name: 'root' }">Go home?</Link>
  </main>
</template>

<script setup lang="ts">import { computed as _computed } from 'vue';

import Link from "$app/components/Link.vue";
import logo from "$assets/images/logo-icon.svg";
const props = defineProps<{
    code: 404 | 403 | 500;
}>();
const heading = _computed(() => {
    switch (props.code) {
        case 403:
            return "Access denied";
        case 404:
            return "Page not found";
        default:
            return "Something went wrong";
    }
});
const text = _computed(() => {
    switch (props.code) {
        case 403:
            return "You are not allowed to perform this action.";
        case 404:
            return "The thing you were looking for doesn't exist... Sorry!";
        default:
            return "Sorry about that. Please try again!";
    }
});
</script>
